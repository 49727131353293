import React from 'react'
import Lateef from '../../images/GaniyuLateef.png'

function About() {
  return (
    <div className='grid grid-cols-2 p-5 lg:p-20' id='about'>
      <div data-wow-duration='2s' className='wow fadeIn Caudex col-span-2 text-[#333333] text-[54px] lg:text-[120px] font-[700] leading-[70px] lg:leading-[155px]'>
        <p>About Me</p>
      </div>
      <div className='col-span-1 hidden lg:block wow fadeIn' data-wow-duration='3s'>
        <img src={Lateef} alt="Ganiyu Lateef"/>
      </div>
      <div data-wow-duration='2s' className='wow fadeInRight Avenir pt-3 lg:pt-0 col-span-2 lg:col-span-1 text-left my-auto text-[16px] lg:text-[20px] font-[400] leading-[150%] text-[#E0E0E0]'>
        <p>
          Hello! My name is Ganiyu Lateef and I enjoy creating amazing 
          web solutions. My interest in web development started back in 2018. 
          When I decided to learn HTML & CSS!
        </p>
        <p className='pt-2'>
          I have worked on multiples of web development projects with different clients. 
          I have had the privilege of working on different projects such as 
          Ecommerce Solutions, WooCommerce, Business websites, 
          Investment Solutions, Portfolio websites, and a lot more. 
        </p>
        <p className='pt-2'>
          I create responsive websites that are fast and easy to use. 
          Here are the few technologies I have worked with in the past 
          few years; PHP, HTML, CSS,  WordPress, Bootstrap.
        </p>
      </div>
    </div>
  )
}

export default About