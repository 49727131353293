import React, { Fragment, useEffect, useState } from 'react'
// import MekFinLogo from '../../images/MekFinLogo.png'
import styled from 'styled-components'

const HeaderWhite = () => {

  const HeaderNav = styled.div`
    .oldnav{
      color:yellow!important;
      transition: all 0.3s ease-in-out;
    }
    .nav{
      display:block!important;
    }

  `
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true)

  const controlHeader = () => {
    if (window.scrollY > 20) {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', controlHeader)
    return () => {
      window.removeEventListener('scroll', controlHeader)
    }
  }, [])
  return (
    <Fragment>
      <HeaderNav className="pb-[100px]">
        <nav className={`wow fadeIn overflow-x-hidden oldnav transition-all ease-out duration-1000 left-0 right-0 Inter flex flex-wrap items-center justify-between px-2 py-3 mb-3 fixed hidden ${showNavbar && 'nav'}`}>
          <div className="container px-2 lg:px-4 mx-auto flex flex-wrap items-center justify-between transition-all ease-out duration-1000 font-Inter">
            <div className="w-full z-10 relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start lg:mt-2 lg:ml-10 xl:ml-8">
              <a
                className="text-sm lg:ml-4 text-[20px] font-[700] leading-relaxed inline-block mr-4 py-2 whitespace-nowrap text-white"
                href="/">
                <span className='text-black'>Ganiyu Lateef.</span>
              </a>

              <button
                className="flex items-center mt-2 px-3 h-[30px] py-2 border rounded text-[#CCE0FF] border-[#CCE0FF] lg:hidden hover:text-white hover:bg-[#CCE0FF] hover:border-[#CCE0FF]"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
              </button>
            </div>
            <div
              className={
                "lg:flex flex-grow bg-white lg:bg-none items-center transition-all lg:mt-[-40px] ease-in duration-1000 lg:ml-[-45px] xl:ml-[140px]" +
                (navbarOpen ? " flex" : " hidden")
              }
              id="example-navbar-danger"
            >
              <ul className="transition-all ease-in duration-500 flex mx-auto flex-col lg:flex-row list-none lg:ml-[160px] xl:ml-[290px] lg:mx-auto">
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/#About"
                  >
                   <span className="ml-2 font-[700] text-[16px] text-black">About</span>
                  </a>
                </li>
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/#Projects"
                  >
                    <span className="ml-2 font-normal text-[16px] text-black">Project</span>
                  </a>
                </li>
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/#Testimonials"
                  >
                    <span className="ml-2 font-normal text-[16px] text-black">Testimonial</span>
                  </a>
                </li>
                <li className="nav-item mx-auto">
                  <a
                    className="px-3 py-2 flex items-center text-xs leading-snug text-white hover:opacity-75"
                    href="/#Contact"
                  >
                   <span className="ml-2 font-normal text-[16px] text-black">Contact</span>
                  </a>
                </li>
                <li className='lg:ml-[130px] xl:ml-40 xl:pl-10'>
                  <ul className="flex flex-col lg:flex-row list-none top-5 ">
                    <li className="nav-item mx-auto ml-[8px] lg:ml-0">
                      <a
                        className="lg:px-3 py-2 flex items-center text-xs leading-snug my-auto text-white hover:opacity-75"
                        href="/#Contact"
                      >
                        <button className="transition-all ease-in duration-500 hover:bg-[#0044FA] rounded-[8px] bg-[#0165FF] w-[100px] lg:w-[141px] lg:h-[40px] pt-1 my-auto px-auto pb-2 pr-2">
                          <span className="ml-2 text-white font-[600] text-[16px] mx-auto my-auto font-Inter">Hire Me</span>
                        </button>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <hr></hr>
      </HeaderNav>
    </Fragment>
  )
}

export default HeaderWhite