import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import testifier from '../../images/testifier.png'
import stars from '../../images/star.png'
import styled from 'styled-components';
import Israel from '../../images/israel-akinola.jpeg'
import Lekan from '../../images/daramola-olalekan.jpeg'
import Tobi from '../../images/tobi-solomon.jpeg'

const Testi = styled.div`
  .testimonial-card:hover {
    background-color: #282C30;
    color:#BCC6D0;
    transition: all 1s ease;
  }
  .testimonial-card:hover i {
    color:#0F1EFF;
    transform: rotate(180deg);
  }
`;
function TestimonialsWhite() {
  return (
    <Testi>
    <div className='grid grid-cols-2 p-5 lg:pl-20 lg:pr-20' id='Testimonials'>
      <div data-wow-duration='3s' className='wow fadeIn Caudex col-span-2 pb-4 lg:pb-5 col-span-2 text-[#333333] text-[54px] lg:text-[120px] font-[700] leading-[70px] lg:leading-[155px]'>
        <p>
          Testimonials
        </p>
      </div>
      <div className='col-span-2 text-center hidden lg:block Sora'>
            <OwlCarousel dotsContainer={false} items={3} autoplay={true} autoplayHoverPause={true} autoplayTimeout={3000}
              className="owl-theme text-center text-[#858B93] font-[400]"
              margin={8} >
            <div className='bg-[#131517] rounded-[10px] duration-2000 grid grid-cols-1 p-5 testimonial-card transition ease-in-out'>
                <div className='col-span-1 ml-60'>
                  <p className=''><i className='text-[24px] fa fa-quote-left text-[#282C30]'/></p>
                </div>
                <div className='col-span-1 mt-2 text-left text-[16px] font-[400] leading-[26px] testimonial-text'>
                  <p>Reynald is a prolific developer who constantly moves in line with development trends. I'm very happy with his work.
                  </p>
                </div>
                <div className='col-span-1 mt-7'>
                  <div className='grid grid-cols-7'>
                    <div className='col-span-6'>
                      <div className='grid grid-cols-5 gap-5'>
                      <div className='col-span-1 h-[50px] w-[50px]'><img className='rounded-[50%] h-[100%] w-[100%]' src={Israel} alt='Testifier'></img></div>
                        <div className='col-span-4 text-left mt-[-10px]'>
                          <div className='grid grid-cols-1'>
                            <div className='col-span-1 text-white text-[18px] font-[600] leading-[28px]'>Israel Akinola</div>
                            <div className='col-span-1 text-[16px] font-[400] leading-[24px]'>Developer</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-span-1 my-auto'><img className='my-auto lg:ml-[-15px] ' src={stars}></img></div>
                  </div>
                </div>
              </div>

            <div className='bg-[#131517] rounded-[10px] duration-2000 grid grid-cols-1 p-5 testimonial-card transition ease-in-out'>
              <div className='col-span-1 ml-60'>
                <p className=''><i className='text-[24px] fa fa-quote-left text-[#282C30]' /></p>
              </div>
              <div className='col-span-1 mt-2 text-left text-[16px] font-[400] leading-[26px] testimonial-text'>
                <p>I have known Lateef for over a year now and he is a very hardworking and dedicated developer.
                He is a very good team player and always ready to help.

                </p>
              </div>
              <div className='col-span-1 mt-7'>
                <div className='grid grid-cols-7'>
                  <div className='col-span-6'>
                    <div className='grid grid-cols-5 gap-5'>
                    <div className='col-span-1 h-[50px] w-[50px]'><img className='rounded-[50%] h-[100%] w-[100%]' src={Lekan} alt='Testifier'></img></div>
                      <div className='col-span-4 text-left mt-[-10px]'>
                        <div className='grid grid-cols-1'>
                          <div className='col-span-1 text-white text-[18px] font-[600] leading-[28px]'>Daramola Lekan</div>
                          <div className='col-span-1 text-[16px] font-[400] leading-[24px]'>Designer</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-1 my-auto'><img className='my-auto lg:ml-[-15px] ' src={stars}></img></div>
                </div>
              </div>
            </div>

            <div className='bg-[#131517] rounded-[10px] duration-2000 grid grid-cols-1 p-5 testimonial-card transition ease-in-out'>
                <div className='col-span-1 ml-60'>
                  <p className=''><i className='text-[24px] fa fa-quote-left text-[#282C30]'/></p>
                </div>
                <div className='col-span-1 mt-2 text-left text-[16px] font-[400] leading-[26px] testimonial-text'>
                  <p>Throughtout the years I have Known Lateef, he has been a great developer.
                  He is a very hardworking and dedicated developer.
                  </p>
                </div>
                <div className='col-span-1 mt-7'>
                  <div className='grid grid-cols-7'>
                    <div className='col-span-6'>
                      <div className='grid grid-cols-5 gap-5'>
                      <div className='col-span-1 h-[50px] w-[50px]'><img className='rounded-[50%] h-[100%] w-[100%]' src={Tobi} alt='Testifier'></img></div>
                        <div className='col-span-4 text-left mt-[-10px]'>
                          <div className='grid grid-cols-1'>
                            <div className='col-span-1 text-white text-[18px] font-[600] leading-[28px]'>Tobi Solomon</div>
                            <div className='col-span-1 text-[16px] font-[400] leading-[24px]'>Developer</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-span-1 my-auto'><img className='my-auto lg:ml-[-15px] ' src={stars}></img></div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
      </div>
        {/* Mobile Display */}
        <div className='col-span-2 text-center lg:hidden Sora'>
          <OwlCarousel dotsContainer={false} items={1} autoplay={true} autoplayHoverPause={true} autoplayTimeout={3000}
            className="owl-theme text-center text-[#858B93] font-[400]"
            margin={8} >
            <div className='bg-[#131517] rounded-[10px] duration-2000 grid grid-cols-1 p-5 testimonial-card transition ease-in-out'>
              <div className='col-span-1 ml-60'>
                <p className=''><i className='text-[24px] fa fa-quote-left text-[#282C30]' /></p>
              </div>
              <div className='col-span-1 mt-2 text-left text-[16px] font-[400] leading-[26px] testimonial-text'>
                <p>Reynald is a prolific developer who constantly moves in line with development trends. I'm very happy with his work.
                </p>
              </div>
              <div className='col-span-1 mt-7'>
                <div className='grid grid-cols-7'>
                  <div className='col-span-6'>
                    <div className='grid grid-cols-5 gap-5'>
                    <div className='col-span-1'><img className='rounded-[50%] h-[100%] w-[100%]' src={Israel} alt='Testifier'></img></div>
                      <div className='col-span-4 text-left mt-[-10px]'>
                        <div className='grid grid-cols-1'>
                          <div className='col-span-1 text-white text-[18px] font-[600] leading-[28px]'>Israel Akinola</div>
                          <div className='col-span-1 text-[16px] font-[400] leading-[24px]'>Developer</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-1 my-auto'><img className='my-auto lg:ml-[-15px] ' src={stars}></img></div>
                </div>
              </div>
            </div>

            <div className='bg-[#131517] rounded-[10px] duration-2000 grid grid-cols-1 p-5 testimonial-card transition ease-in-out'>
              <div className='col-span-1 ml-60'>
                <p className=''><i className='text-[24px] fa fa-quote-left text-[#282C30]' /></p>
              </div>
              <div className='col-span-1 mt-2 text-left text-[16px] font-[400] leading-[26px] testimonial-text'>
                <p>I have known Lateef for over a year now and he is a very hardworking and dedicated developer.
                He is a very good team player and always ready to help.
                </p>
              </div>
              <div className='col-span-1 mt-7'>
                <div className='grid grid-cols-7'>
                  <div className='col-span-6'>
                    <div className='grid grid-cols-5 gap-5'>
                    <div className='col-span-1'><img className='rounded-[50%] h-[100%] w-[100%]' src={Lekan} alt='Testifier'></img></div>
                      <div className='col-span-4 text-left mt-[-10px]'>
                        <div className='grid grid-cols-1'>
                          <div className='col-span-1 text-white text-[18px] font-[600] leading-[28px]'>Daramola Lekan</div>
                          <div className='col-span-1 text-[16px] font-[400] leading-[24px]'>Designer</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-1 my-auto'><img className='my-auto lg:ml-[-15px] ' src={stars}></img></div>
                </div>
              </div>
            </div>

            <div className='bg-[#131517] rounded-[10px] duration-2000 grid grid-cols-1 p-5 testimonial-card transition ease-in-out'>
              <div className='col-span-1 ml-60'>
                <p className=''><i className='text-[24px] fa fa-quote-left text-[#282C30]' /></p>
              </div>
              <div className='col-span-1 mt-2 text-left text-[16px] font-[400] leading-[26px] testimonial-text'>
                <p>Throughtout the years I have Known Lateef, he has been a great developer.
                  He is a very hardworking and dedicated developer.
                </p>
              </div>
              <div className='col-span-1 mt-7'>
                <div className='grid grid-cols-7'>
                  <div className='col-span-6'>
                    <div className='grid grid-cols-5 gap-5'>
                    <div className='col-span-1'><img className='rounded-[50%] h-[100%] w-[100%]' src={Tobi} alt='Testifier'></img></div>
                      <div className='col-span-4 text-left mt-[-10px]'>
                        <div className='grid grid-cols-1'>
                          <div className='col-span-1 text-white text-[18px] font-[600] leading-[28px]'>Tobi Solomon</div>
                          <div className='col-span-1 text-[16px] font-[400] leading-[24px]'>CEO, CPTS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-1 my-auto'><img className='my-auto lg:ml-[-15px] ' src={stars}></img></div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
    </div>
    </Testi>
  )
}

export default TestimonialsWhite