import React from 'react'
import Instagram from '../../icons/instagram.png'
import Twitter from '../../icons/twitter.png'
import Linkedin from '../../icons/linkedin.png'
import Whatsapp from '../../icons/whatsapp.png'


function Contact() {
  return (
    <div className='grid grid-cols-1 p-5 lg:p-20' id='contact'>
      <div data-wow-duration='2s' className="wow fadeIn Caudex col-span-1 pl-8 lg:mt-3 col-span-2 pb-3 text-left col-span-2 text-[#333333] text-[40px] lg:text-[120px] lg:text-[120px] font-[700] leading-[70px] lg:leading-[155px]">
        <p>Contact Me</p>
      </div>
      <div className="col-span-1">
        <hr style={{ border: '0.1px solid #828282' }}></hr>
      </div>
      <div className="col-span-1 pt-3 lg:pt-7">
        <div className='grid grid-cols-4'>
          <div className='col-span-4 lg:col-span-3'>
            <div className='grid grid-cols-1 text-left'>
              <div className='col-span-1 font-[700] text-[24px] lg:text-[50px] leading-[30.94px] lg:leading-[82px] text-[#fff] pt-1 lg:pt-0 Caudex'><p>Let's Get Connected</p></div>
              <div className='col-span-1 font-400] text-[14px] lg:text-[18px] leading-[25px] text-[#E0E0E0] leading-[19.12px] lg:leading-[25.59px] pt-2 lg:pt-0 Avenir'><p>Have any idea you would like to implement? Let's connect.</p></div>
            </div>
          </div>
          <div className='col-span-4 lg:col-span-1 my-auto pt-5 Avenir'>
            <div data-wow-duration='2s' className='wow jello grid grid-cols-1 text-[#E0E0E0] font-[400] text-[16px] lg:text-[20px] leading-[21.86px] lg:leading-[27px] text-right'>
              <div className='col-span-1'>
                <div className='grid grid-cols-2 gap-10'>
                  <div className='col-span-1'>
                    <a href='https://www.instagram.com/reynaldkng/' target='_blank' className='grid grid-cols-2 gap-0'>
                      <div className='col-span-1'><img src={Instagram}></img></div>
                      <div className='col-span-1 text-left ml-[-25px]'>Instagram</div>
                    </a>
                  </div>
                  <div className='col-span-1'>
                    <a href='https://www.linkedin.com/in/lateef-ganiyu-reynald/' target='_blank' className='grid grid-cols-2 gap-0'>
                      <div className='col-span-1'><img src={Linkedin}></img></div>
                      <div className='col-span-1 text-left ml-[-25px]'>LinkedIn</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-span-1 pt-5'>
                <div className='grid grid-cols-2 gap-10'>
                  <div className='col-span-1'>
                    <a href='https://www.twitter.com' target='_blank' className='grid grid-cols-2 gap-0'>
                      <div className='col-span-1'><img src={Twitter}></img></div>
                      <div className='col-span-1 text-left ml-[-25px]'>Twitter</div>
                    </a>
                  </div>
                  <div className='col-span-1'>
                    <a href='https://wa.me/+2347016353712' target='_blank' className='grid grid-cols-2 gap-0'>
                      <div className='col-span-1'><img src={Whatsapp}></img></div>
                      <div className='col-span-1 text-left ml-[-25px]'>Whatsapp</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-1 pt-7 lg:pt-10">
        <hr style={{ border: '0.1px solid #828282' }}></hr>
      </div>
    </div>
  )
}

export default Contact