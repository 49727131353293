import './App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Renald from './renald';
import Error from './modules/404';
import WOW from 'wowjs';

class App extends Component {

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Renald />}></Route>
          <Route path="*" element={<Error />}></Route>
        </Routes>
      </Router>
    );
  }
}

export default App;


