import React from 'react'
import complanetTechnologies from '../../images/cpts.png';

function Projects() {
  return (
    <div className='grid grid-cols-2 lg:pl-20 p-5 lg:pr-20 lg:mt-[-50px] gap-5' id="projects">
      <a href='https://www.complanettechnologies.com.ng' target='_blank' data-wow-duration='2s' className='wow fadeIn Caudex mt-4 lg:mt-3 pb-3 col-span-2 text-[#333333] text-[54px] lg:text-[120px] font-[700] leading-[70px] lg:leading-[155px]'>
        <p>Projects</p>
      </a>
      <a href='https://www.complanettechnologies.com.ng' target='_blank' data-wow-duration='1s' className='wow fadeInUp col-span-2 lg:col-span-1 lg:mt-2'>
        <img src={complanetTechnologies} alt="Complanet Technologies" />
        <p className='text-left mt-2 font-[900] Avenir text-[18px] lg:text-[32px] leading-[44px] text-[#E0E0E0]'>Complanet Technologies</p>
      </a>
      <a href='https://www.complanettechnologies.com.ng' target='_blank' data-wow-duration='1s' className='wow fadeInUp col-span-2 lg:col-span-1 lg:mt-2'>
        <img src={complanetTechnologies} alt="Complanet Technologies" />
        <p className='text-left mt-2 font-[900] Avenir text-[18px] lg:text-[32px] leading-[44px] text-[#E0E0E0]'>Complanet Technologies</p>
      </a>
      <a href='https://www.complanettechnologies.com.ng' target='_blank' data-wow-duration='1s' className='wow fadeInUp col-span-2 lg:col-span-1 lg:mt-2'>
        <img src={complanetTechnologies} alt="Complanet Technologies" />
        <p className='text-left mt-2 font-[900] Avenir text-[18px] lg:text-[32px] leading-[44px] text-[#E0E0E0]'>Complanet Technologies</p>
      </a>
      <a href='https://www.complanettechnologies.com.ng' target='_blank' data-wow-duration='1s' className='wow fadeInUp col-span-2 lg:col-span-1 lg:mt-2'>
        <img src={complanetTechnologies} alt="Complanet Technologies" />
        <p className='text-left mt-2 font-[900] Avenir text-[18px] lg:text-[32px] leading-[44px] text-[#E0E0E0]'>Complanet Technologies</p>
      </a>
    </div>
  )
}

export default Projects