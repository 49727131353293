import React from 'react'
import styled from 'styled-components';
import HeroImage from '../../images/hero-circle.png';
import Reynald from '../../images/reynald.png';

function HeroWhite() {

  const ReyHero = styled.div`

  `;
  return (
    <ReyHero>
    <div className='grid grid-cols-2 p-5 lg:p-0 overflow-x-hidden'>
        <div className='col-span-2 lg:pt-40 lg:col-span-1 lg:text-center'>
          <div className='grid grid-cols-1'>
            <div className='col-span-1 lg:ml-10 lg:pl-8 text-left mt-[-30px] lg:mt-0 font-Inter'>
              <p className='font-[700] text-[40px] lg:text-[76px] text-black leading-[62px] lg:leading-[98%] Caudex wow fadeInLeft' data-wow-duration="2s">Hi, I'm Reynald.</p>
            </div>
          <div className='col-span-1 lg:ml-[74px] text-left mt-2 lg:mt-10 font-[400] text-[16px] lg:leading-[150%] lg:text-[20px] leading-[24px] text-black font-Avenir wow fadeInLeft' data-wow-duration="3s">
            <p className='Avenir'>
              I'm a software developer who specializes in creating amazing digital experiences. 
              I'm excited about using data-driven processes to turn company objectives 
              into solutions for solve challenging problems.
            </p>
            <p className=''>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
              Euismod commodo rutrum convallis egestas proin vel ut.rhn ahksksgk hhk. */}
            </p>
            </div>
            <div className='col-span-1 lg:ml-10 lg:pl-1 mt-5'>
              <div className='grid grid-cols-2'>
                <div className='col-span-1'>
                  <div className='grid grid-cols-2 gap-20 lg:gap-0 Inter'>
                    <a href='/#Contact' className='col-span-1 wow fadeInUp' data-wow-duration='2s' data-wow-delay='1s'>
                      <button className='bg-[#0165FF] rounded-[8px] w-[100px] h-[40px] text-white transition-all ease-in duration-500 hover:bg-[#0044FA]'>Hire Me</button>
                    </a>
                    <a href='/#Contact' className='col-span-1 wow fadeInUp' data-wow-duration='2s' data-wow-delay='1s'>
                      <button className='bg-white border-solid border-2 border-[#0044FA] rounded-[8px] w-[147px] h-[40px] text-[#0044FA] transition-all ease-in duration-500 hover:bg-[#0165FF] hover:text-[#fff]'>Download CV</button>
                    </a>
                  </div>
                </div>
                <div className='col-span-1'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-2 lg:col-span-1'>
          <div className='grid grid-cols-5 gap-0 lg:ml-[-50px]'>
            <div className='col-span-2 lg:col-span-1 pt-7'>
              <img className='w-[75%] lg:w-full animate-spinSlow' src={HeroImage} alt='hero' />
            </div>
            <div className='col-span-3 lg:col-span-4 ml-[-100px] lg:ml-0 mt-8 lg:mt-0 wow fadeIn' data-wow-duration='4s'>
              <img className='w-full lg:ml-[-100px]' src={Reynald} alt='hero' />
            </div>
          </div>
        </div>
      </div>
      </ReyHero>
  )
}

export default HeroWhite