import React, { Fragment,useState,useEffect } from 'react'
import About from '../modules/about'
import AboutWhite from '../modules/aboutWhite'
import Header from '../modules/header'
import HeaderWhite from '../modules/headerWhite'
import Hero from '../modules/hero'
import HeroWhite from '../modules/heroWhite'
import Projects from '../modules/projects'
import ProjectsWhite from '../modules/projectsWhite'
import Testimonials from '../modules/testimonials'
import TestimonialsWhite from '../modules/testimonialsWhite'
import mode from '../images/mode.png'
import Contact from '../modules/contact'
import ContactWhite from '../modules/contactWhite'


const Renald = () => {
  const whiteBackground = () =>{
    document.getElementById('dark').style.display='none'
    document.getElementById('white').style.display='block'
  }
  const darkBackground = () =>{
    document.getElementById('white').style.display='none'
    document.getElementById('dark').style.display='block'
  }
  return (
    <Fragment>
          <div className='App transition-all ease-out duration-3000 ' id='dark'>
            <Header></Header>
            <Hero></Hero>
            <About></About>
            <Projects></Projects>
            <Testimonials></Testimonials>
            <div className='fixed right-0 lg:right-20 bottom-0 cursor-pointer' onClick={whiteBackground}>
              <img className='w-[50%] lg:w-[75%]' src={mode}/>
            </div>
            <Contact></Contact>
          </div>

          <div className='AppWhite transition-all ease-out duration-3000 ' style={{display:'none'}} id='white'>
            <HeaderWhite></HeaderWhite>
            <HeroWhite></HeroWhite>
            <AboutWhite></AboutWhite>
            <ProjectsWhite></ProjectsWhite>
            <TestimonialsWhite></TestimonialsWhite>
            <div className='fixed right-0 lg:right-20 bottom-0 cursor-pointer' onClick={darkBackground}>
              <img className='w-[50%] lg:w-[75%]' src={mode}/>
            </div>
            <ContactWhite></ContactWhite>
          </div>
    </Fragment>
    
  );

}

export default Renald